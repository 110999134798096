<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">系统设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{
            stuJson.stu == "add" ? "新增课程推荐设置" : "修改课程推荐设置"
          }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="bannerList">
              <div class="FormBox">
                <div class="form-box">
                  <h3>课程基本信息</h3>
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                  >
                    <el-form-item label="课程名称" prop="recommendName">
                      <div style="display: flex">
                        <el-input
                          v-model="baseform.recommendName"
                          size="small"
                          maxlength="50"
                          show-word-limit
                          :disabled="this.$route.query.stu == 'look'"
                        >
                          <template slot="append">
                            <el-button
                              @click="couserFocus"
                              :disabled="this.$route.query.stu == 'look'"
                              >搜索</el-button
                            ></template
                          >
                        </el-input>
                      </div>

                      <!-- <p v-show='baseform.rotationName.length==20' style="color:#f46173">不能超过20个字</p> -->
                    </el-form-item>
                    <el-form-item label="推荐系统" prop="terminal">
                      <el-checkbox-group v-model="baseform.terminal" :disabled="this.$route.query.stu == 'look'">
                        <el-checkbox label="2" >安知培训</el-checkbox>
                        <el-checkbox label="1">APP</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="推荐位置" prop="recommendSite">
                      <!-- <el-radio-group
                        v-model="baseform.recommendSite"
                        size="small"
                      >
                        <el-radio  v-for="(item,index) in recommendSites" :key="index" :label="item.value">{{ item.label }}</el-radio>
                      </el-radio-group> -->
                      <el-cascader
                        v-model="baseform.recommendSite"
                        :disabled="this.$route.query.stu == 'look'"
                        :options="RecommendList"
                        :props="propremend"
                        placeholder="请选择推荐位置"
                        clearable
                        filterable
                        size="small"
                      ></el-cascader>
                    </el-form-item>
                    <el-form-item label="行政区划" prop="areaId">
                      <el-cascader
                        v-model="baseform.areaId"
                        :options="areatreeList"
                        :props="propsarea"
                        :disabled="this.$route.query.stu == 'look'"
                        placeholder="请选择行政区划"
                        clearable
                        filterable
                        size="small"
                      ></el-cascader>
                    </el-form-item>
                    <el-form-item
                      label="课程封面"
                      prop="picFullPath"
                      :class="[rotationId ? 'item-disabled' : '']"
                      required
                    >
                      <el-upload
                        :disabled="this.$route.query.stu == 'look'"
                        :on-change="handleAvatarSuccess2"
                        :before-upload="$beforeAvatarUpload"
                        :http-request="$requestMine"
                        :show-file-list="false"
                        class="img-el-upload"
                        action
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <el-image
                          :src="
                            baseform.picFullPath ||
                            require('@/assets/develop.png')
                          "
                          fit="contain"
                          class="imgCenter"
                        ></el-image>
                      </el-upload>
                      <p style="color: #f46173">APP建议上传尺寸700*240</p>
                    </el-form-item>
                    <el-form-item
                      label="课程简介"
                      prop="thumbnail"
                      class="bannerBox"
                    >
                      <el-upload
                        action
                        :disabled="this.$route.query.stu == 'look'"
                        :file-list="baseform.fileList"
                        list-type="picture-card"
                        :on-change="handlePictureCardPreview"
                        :before-upload="beforeAvatarUpload2"
                        :http-request="$requestMine"
                        limit="5"
                        class="df"
                        :class="{ hide: hideUploadCard }"
                        :on-remove="handleRemove"
                      >
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="课程描述" prop="description">
                      <el-input
                        v-model="baseform.description"
                        :disabled="this.$route.query.stu == 'look'"
                        size="small"
                        maxlength="255"
                        type="textarea"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="推荐状态" prop="enable">
                      <el-select
                        v-model="baseform.enable"
                        :disabled="this.$route.query.stu == 'look'"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in bannerStateList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
                <div>
                  <h3 style="padding-bottom: 20px">课程章节</h3>
                  <section class="chapterl-trees createdCourse">
                    <el-tree
                      :data="data"
                      node-key="id"
                      default-expand-all
                      :draggable="draggable && !disabled"
                      :allow-drop="allowDrop"
                      :allow-drag="allowDrag"
                      @node-drop="handleDrop"
                    >
                      <div class="custom-tree-node" slot-scope="{ node, data }">
                        <p class="flexac" v-if="data.level === 1">
                          <span style="width: 44px; text-align: left">
                            <span class="level" style="margin-right: 10px">
                              <!-- 一级 -->
                              {{ data.chapterNum }}
                            </span>
                          </span>
                          <el-input
                            ref="input"
                            size="mini"
                            style="width: 130px"
                            v-if="data.isEdit == 1"
                            @blur="submitEdit(node, data)"
                            @keyup.enter.native="handleEnter($event)"
                            v-model="treeLable"
                            maxlength="30"
                          />
                          <span
                            :title="data.catalogName"
                            class="ellipsis"
                            style="margin-right: 20px"
                            v-else
                            >{{ data.catalogName }}</span
                          >
                          <span class="trees-btns">
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              :disabled="disabled && !data.isEdit"
                              @click.stop="() => doTreeDel(node, data)"
                              >删除</el-button
                            >
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doTreeEdit(node, data)"
                              :disabled="disabled"
                              >重命名</el-button
                            >
                          </span>
                        </p>
                        <template v-else-if="data.level === 2">
                          <draggable
                            class="tree-drag-group"
                            :group="{
                              name: 'people',
                              put: true,
                              pull: false,
                            }"
                            :sort="false"
                            @change="
                              (evt) => {
                                nodeChange(evt, data);
                              }
                            "
                            handle=".good"
                            :prevent-on-filter="true"
                          >
                            <span style="width: 60px; text-align: left">
                              <span
                                class="level"
                                v-if="styleType == '1'"
                                style="margin-right: 10px"
                              >
                                <!-- 二级 -->
                                {{ data.chapterNum }}
                              </span>
                              <span
                                class="level"
                                v-else
                                style="margin-right: 10px"
                              >
                                <!-- 一级 -->
                                {{ data.chapterNum }}
                              </span>
                            </span>
                            <el-input
                              ref="input"
                              size="mini"
                              style="width: 120px"
                              v-if="data.isEdit == 1"
                              @blur="submitEdit(node, data)"
                              @keyup.enter.native="handleEnter($event)"
                              v-model="treeLable"
                              maxlength="30"
                            />
                            <span
                              :title="data.catalogName"
                              class="ellipsis"
                              style="margin-right: 3px; width: 480px"
                              v-else
                              >{{ data.catalogName }}</span
                            >
                            <span class="trees-btns">
                              <el-button
                                style="padding: 7px 10px"
                                size="mini"
                                :disabled="disabled && !data.isEdit"
                                @click.stop="() => doTreeDel(node, data)"
                                >删除</el-button
                              >
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeEdit(node, data)"
                                :disabled="disabled"
                                >重命名</el-button
                              >

                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeTrysee(node, data)"
                                :disabled="disabled"
                                >{{
                                  data.trySee == false ? "开启试看" : "关闭试看"
                                }}</el-button
                              >
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => showVideo(data)"
                                >预览</el-button
                              >
                            </span>
                          </draggable>
                        </template>
                      </div>
                    </el-tree>
                  </section>
                </div>
              </div>
              <div class="btn-box">
                <el-button type="primary" @click="doCancel" class="bgc-bv"
                  >取 消</el-button
                >
                <el-button
                  type="primary"
                  class="bgc-bv"
                  @click="doEditSave"
                  v-show="this.$route.query.stu != 'look'"
                  >确 定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <videoCoursePop
      ref="videoCoursePop"
      @eventBus="courseTableMoreBack"
    ></videoCoursePop>
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :kpointSource="10"
        :videoTime="videoTime"
        v-if="videoType"
        ref="player"
      />
      <player_1
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :kpointSource="10"
        :videoTime="videoTime"
        v-else
        ref="player"
      />
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import videoCoursePop from "@/views/operate/videoReconmmendation/videoCoursePop";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
export default {
  name: "operate/videoCourseEdit",
  components: {
    // tree,
    // PageNum,
    // Empty,
    videoCoursePop,
    player_1,
    player_2,
  },
  mixins: [List],
  data() {
    return {
      rotationId: "",
      dialogVisible: false,
      stuJson: {
        stu: this.$route.query.stu,
      },
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propremend: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 轮播图状态
      bannerStateList: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],
      // 基本信息
      baseform: {
        recommendName: "", //视频名称
        terminal: [], //推荐系统
        recommendSite: "", //推荐位置
        areaId: "-10000", // 行政区划
        picPath: "", //图片地址
        kpointvideoName: "", //视频
        picFullPath: "", //上传的图片，
        enable: true, // 推荐状态
        recommendContent: "",
        width: "",
        height: "",
        fileList: [],
        srcImg: [],
        thumbnail: [],
      },
      // 获取推荐位置数据
      recommendSites: [],
      rules: {
        recommendName: [
          { required: true, trigger: "blur", message: "请输入课程推荐名称" },
        ],
        terminal: [
          { required: true, trigger: "change", message: "请选择推荐系统" },
        ],
        recommendSite: [
          { required: true, trigger: "blur", message: "请选择推荐位置" },
        ],
        areaId: [
          { required: true, trigger: "blur", message: "请选择行政区划" },
        ],
        picFullPath: [
          { required: true, trigger: "change", message: "请上传封面图片" },
        ],

        description: [
          { required: true, trigger: "blur", message: "请输入课程描述" },
        ],
        enable: [
          { required: true, trigger: "change", message: "请选择推荐状态" },
        ],
      },
      data: [],
      styleType: 1,
      treeLable: "",
      disabled: false,
      draggable: true,
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.recommendId = this.$route.query.id;
      this.baseform.courseId = this.$route.query.courseId;
      this.getDetail();
    }
    if (this.$route.query.stu == "look") {
      this.disabled = true;
    }
    this.getareatree();
    this.getRecommendList();
    this.getRecommendedLocation();
  },
  mounted() {
    // this.editorInit();
  },
  computed: {},
  methods: {
    /* 资源提供者 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    couserFocus() {
      this.$refs.videoCoursePop.showPopUp();
    },
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.baseform.picFullPath = result.data.fileURL;
          that.baseform.picPath = result.data.fileKey;
          console.log(that.baseform.picFullPath);
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function () {
          that.baseform.width = img.width || 375;
          that.baseform.height = img.height || 200;
          file.width = img.width;
          file.height = file.width;
        };
      };
      reader.readAsDataURL(file);

      return isLt2M;
    },
    /* 上传图片start */
    handlePictureCardPreview(res, fileList) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_WARE");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.baseform.srcImg.push(result.data.fileURL);
            let aWidth = this.baseform.width || 375;
            let aHeight = this.baseform.height || 250;
            this.baseform.thumbnail.push({
              width: aWidth,
              height: aHeight,
              picPath: result.data.fileKey,
            });
            this.hideUploadCard =
              this.baseform.thumbnail.length == 5 ? true : false;
            console.log(this.baseform.thumbnail);
          })
          .catch(() => {
            return;
          });
      }
    },
    /* 上传图片end */
    /* 删除图片start */
    handleRemove(res, fileList) {
      this.baseform.thumbnail = [];
      fileList.map((item) => {
        this.baseform.thumbnail.push({
          width: item.width || item.raw.width,
          height: item.height || item.raw.height,
          picPath: item.name,
        });
      });
      this.hideUploadCard = fileList.length == 5 ? true : false;
    },
    /* 删除图片end */
    //点击编辑获取数据
    getDetail() {
      this.$post("/sys/recommend/course/id", {
        recommendId: this.$route.query.id,
      })
        .then((res) => {
          if (res.data.isChapter) {
            this.styleType = 1;
            res.data.outlineList.map((el) => {
              el.level = 1;
              el.children.map((els) => {
                els.level = 2;
              });
              this.data = res.data.outlineList;
            });
          } else {
            this.styleType = 2;
            res.data.outlineList.map((el) => {
              let moreCourse = [];
              moreCourse.push({
                ind: new Date().getTime(),
                level: 2,
                catalogName: el.catalogName,
                isEdit: "0",
                videoId: el.videoId,
                section: el.section,
                trySee: el.trySee,
              });
              this.data = [...this.data, ...moreCourse];
            });
          }
          this.mapData(this.data);
          //  this.baseform.terminal = res.data.terminal.split(",");
          this.baseform = {
            ...this.baseform,
            ...res.data,
            picFullPath: res.data.showPicPath,
            thumbnail: res.data.introductionList || [],
            terminal: res.data.terminal.split(","),
          };
          
          if (res.data.introductionList) {
            for (var i = 0; i < res.data.introductionList.length; i++) {
              this.baseform.fileList.push({
                name: res.data.introductionList[i].picPath,
                url: res.data.introductionList[i].showPicPath,
                width: res.data.introductionList[i].width,
                height: res.data.introductionList[i].height,
              });
            }
          }

          //   this.baseform.kpointvideoName = baseform.kpointName;
          //   this.kpointVideoId = baseform.kpointVideoId;
        })
        .catch(() => {});
    },
    // 编辑保存
    doEditSave() {
      if (!this.data.length) {
        this.$message({
          message: "课程章节不能为空",
          type: "error",
        });
        return;
      }
      const params = {
        recommendName: this.baseform.recommendName, //
        areaId: this.baseform.areaId || "", //行政区划
        terminal: this.baseform.terminal.toString(), // 发布系统
        recommendSite: this.baseform.recommendSite, // 轮播图状态
        picPath: this.baseform.picPath, //课程封面地址
        enable: this.baseform.enable,
        description: this.baseform.description,
        introductionList: this.baseform.thumbnail,
        courseId: this.baseform.courseId || this.$route.query.courseId,
        outlineList: this.data,
      };
      if (this.$route.query.id) {
        params.recommendId = this.$route.query.id;
      }
      if (this.data.length) {
        params.isChapter = this.data[0].section == false ? false : true;
      }
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          //   if (editor.txt.text().trim() == "") {
          //     this.$message.warning("请输入推荐详情");
          //     return;
          //   }
          this.$post(
            this.$route.query.id
              ? "/sys/recommend/course/modify"
              : "/sys/recommend/course/insert",
            params
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.$router.push({
                  path: "/web/operate/videolist",
                  query: {
                    refresh: true,
                  },
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 点击取消
    doCancel() {
      this.$router.back();
    },
    // 批量选课件
    courseTableMoreBack(ret) {
      this.data = [];
      this.baseform.recommendName = ret.courseName;
      this.$refs["baseform"].clearValidate("recommendName");
      this.baseform.courseId = ret.courseId;
      this.$post("/run/course/own/details", { courseId: ret.courseId }).then(
        (res) => {
          if (res.status == "0") {
            console.log(res.data);
            this.baseform.picFullPath = res.data.showLogo;
            this.baseform.picPath = res.data.logo;
            let isChapter = res.data.isChapter;
            this.disabled = false;
            if (isChapter) {
              this.styleType = 1;
              res.data.outline.map((el) => {
                el.level = 1;
                el.children.map((els) => {
                  els.level = 2;
                });
                this.data = res.data.outline;
              });
            } else {
              this.styleType = 2;
              res.data.outline.map((el) => {
                console.log(el);
                let moreCourse = [];
                moreCourse.push({
                  ind: new Date().getTime(),
                  level: 2,
                  catalogName: el.catalogName,
                  recommendOutlineId: el.recommendOutlineId,
                  isEdit: "0",
                  videoId: el.videoId,
                  section: el.section,
                  trySee: el.trySee,
                  progress: 100,
                  kpointId: el.kpointId,
                });
                this.data = [...this.data, ...moreCourse];
              });
            }
            this.mapData(this.data);
            this.data = res.data.outline;
          }
        }
      );
      //   if (this.datas.label == "") {
      //     this.$set(this.datas, "label", ret.kpointName);
      //     this.$set(this.datas, "isEdit", 0);
      //     this.disabled = false;
      //   }
      //   const childBack = [];
      //   if (!this.addBtnIsOut) {
      //     for (let i = 0; i < ret.length; i++) {
      //       const element = ret[i];
      //       const paperChild = [];
      //       if (element.paperId) {
      //         paperChild.push({
      //           ind: new Date().getTime(),
      //           level: 3,
      //           list: [],
      //           label: element.paperName,
      //           paperName: element.paperName,
      //           paperId: element.paperId
      //         });
      //       }
      //       childBack.push({
      //         ind: new Date().getTime(),
      //         level: 2,
      //         list: [
      //           {
      //             ind: new Date().getTime(),
      //             kpointId: element.kpointId,
      //             kpointName: element.kpointName,
      //             name: element.kpointName,
      //             progress: 100,
      //             teacher: element.teacherName,
      //             category: element.trainTypeName,
      //             kpointLessonNum: element.kpointLessonNum,
      //             kpointVideoId: element.kpointVideoId,
      //             kpointLogo: element.kpointLogo,
      //             kpointDuration: element.kpointDuration,
      //             kpointType: element.kpointType,
      //             qualificationId: element.qualificationId,
      //             paperId: element.paperId,
      //             paperName: element.paperName,
      //             buyId: element.buyId || ""
      //           }
      //         ],
      //         label: element.kpointName,
      //         children: paperChild,
      //         paperName: ret.paperName,
      //         paperId: ret.paperId
      //       });
      //     }
      //     this.$set(this.datas, "children", [
      //       ...this.datas.children,
      //       ...childBack
      //     ]);
      //   } else {
      //     for (let i = 0; i < ret.length; i++) {
      //       const element = ret[i];
      //       const paperChild = [];
      //       if (element.paperId) {
      //         paperChild.push({
      //           ind: new Date().getTime(),
      //           level: 3,
      //           list: [],
      //           label: element.paperName,
      //           paperName: element.paperName,
      //           paperId: element.paperId
      //         });
      //       }
      //       const moreCourse = [];
      //       moreCourse.push({
      //         ind: new Date().getTime(),
      //         level: 2,
      //         label: element.kpointName,
      //         isEdit: "0",
      //         children: paperChild,
      //         list: [
      //           {
      //             ind: new Date().getTime(),
      //             kpointId: element.kpointId,
      //             kpointName: element.kpointName,
      //             name: element.kpointName,
      //             progress: 100,
      //             teacher: element.teacherName,
      //             category: element.trainTypeName,
      //             kpointLessonNum: element.kpointLessonNum,
      //             kpointVideoId: element.kpointVideoId,
      //             kpointLogo: element.kpointLogo,
      //             kpointDuration: element.kpointDuration,
      //             kpointType: element.kpointType,
      //             qualificationId: element.qualificationId,
      //             paperId: element.paperId,
      //             paperName: element.paperName,
      //             buyId: element.buyId || ""
      //           }
      //         ]
      //       });
      //       this.data = [...this.data, ...moreCourse];
      //     }
      //   }
      //   this.mapData(this.data);
    },
    handleDragStart(node) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode) {
      console.log("tree drag finish: ", draggingNode);
      this.mapData(this.data);
      // this.updataBack2Index();
    },
    // tree中非同级 不能拖拽
    allowDrop(draggingNode, dropNode, type) {
      const tlevel = dropNode.data.level;
      const plevel = draggingNode.data.level;
      if (plevel === tlevel && type !== "inner") {
        // 同级
        return true;
      } else if (plevel === tlevel + 1 && type === "inner") {
        // 子集
        return true;
      } else {
        return false;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.catalogName.indexOf("三级 3-2-2") === -1;
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.children) {
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    /* 试看 */
    doTreeTrysee(node, data) {
      console.log(data);
      if (data.trySee) {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      } else {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      }
      //
    },
    //重命名
    doTreeEdit(node, data) {
      this.treeLable = data.catalogName;
      this.disabled = true;
      this.$set(node, "isEdit", 1);
      this.$set(data, "isEdit", 1);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    submitEdit(node, data) {
      // treeLable----目录名称
      if (this.treeLable.trim()) {
        this.$set(data, "catalogName", this.treeLable);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treeLable = "";
        this.disabled = false;
      } else {
        this.$message.warning("请输入名称");
      }
    },
    handleEnter(event) {
      if (this.treeLable.trim()) {
        event.target.blur();
      } else {
        this.$message.warning("请输入名称");
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    //删除
    doTreeDel(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.indexOf(data);
      if (data.level == 1) {
        let name = "二级";
        if (this.styleType == 2) {
          name = "一级";
        }
        this.$confirm("你确定删除该一级项及下面所有二级项内容?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            children.splice(index, 1);
            this.disabled = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.mapData(this.data);
          })
          .catch(() => {});
      } else if (data.level == 2) {
        this.$confirm("你确定删除该视频吗?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            children.splice(index, 1);
            this.mapData(this.data);
            this.disabled = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    //预览
    // showVideo(data) {
    //   console.log(data);
    //   this.$store.dispatch("handleVideoUrl", {
    //     kpointVideoId: data.videoId,
    //     kpointName: data.catalogName,
    //     courseId:this.baseform.courseId,
    //     kpointId:data.kpointId
    //   });
    // },
    // 预览
    showVideo(data) {
      console.log(data);
      let _this = this;
      //单元测试打开是题库
      _this
        .$post("/biz/courseware/auth", { videoId: data.videoId })
        .then((result) => {
          _this.videoId = data.videoId;
          _this.videoType = data.trySee;
          _this.videoTime = "300";
          _this.videotitle = data.catalogName;
          _this.courseId = _this.baseform.courseId;
          _this.kpointId = data.kpointId || "";
          _this.dialogVisible1 = true;
        });
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.player.dd();
      distorySwigger();
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        const list = [{ value: "-10000", label: "全部区域" }];
        for (const key in ret.data) {
          list.push({
            value: ret.data[key].value,
            label: ret.data[key].label,
            children: ret.data[key].children,
          });
        }
        this.areatreeList = list;
      });
    },
    getRecommendList() {
      this.$post("/biz/recommend/site/tree", { level: 2 }).then((ret) => {
        let list = [];
        for (const key in ret.data) {
          list.push({
            id: ret.data[key].id,
            label: ret.data[key].label,
            children: ret.data[key].children,
          });
        }
        this.RecommendList = list;
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 获取推荐位置
    getRecommendedLocation() {
      const CrowdtypeList = this.$setDictionary("RECOMMENDSITE", "list");
      const list = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      this.recommendSites = list;
    },
  },
};
</script>
<style lang="less" scoped>
.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    /deep/.el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}
</style>
<style lang="less" scoped>
.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
  .FormBox {
    flex: 1;
  }
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 480px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
}
.dialog-footer /deep/ {
  display: flex;
  justify-content: center;
}
</style>
